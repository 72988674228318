a{
  text-decoration: none;
}
.home{
    display: flex;

}

.container{
    flex: 6;
    font-family: 'Cairo', sans-serif;
    .addBtn{
    
      width: auto;
      margin: auto;
      margin-top: 32px;
        border-radius: 8px;
        padding: 10px 30px;
        font-size: 18px;
        width: auto;
        background-color: #9b59b6;
        color: white;
        font-weight: 600;
        text-transform: capitalize;
        width: 200px;
        text-align: center;
    
    }
    .product-contianer{

        flex-wrap: wrap;
        display: flex;
   
    }
}
.product{
    background-color: #fff;
    border-radius: 22px;
    box-shadow: 0 0 16px rgba(17,17,26,.1);
    flex-direction: column;
    height: auto;
    cursor: pointer;
    margin: 20px;
    transition: -webkit-transform .7s;
    transition: transform .7s;
    transition: transform .7s,-webkit-transform .7s;
    width: 350px;
    img{
        width: 100%;
        height: 300px;
        object-fit: contain;
    }
    .title{
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        text-transform: capitalize;
    }
    .buttons{
        width: 60%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        span{
            text-align: center;
cursor: pointer;
            width: auto;
            transition: 0.5s;
&:hover{
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
   

}
        }
        
        .delete{
            background-color: red;
            color: white;
             display: flex;
             justify-content: center;
             padding: 10px 20px;
             border-radius: 6px;
             margin-bottom: 10px;
             gap: 5px;
        }
.edit{
    background-color: #9b59b6;
    color: white;
    display: flex;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 6px;
    gap: 5px;
    margin-bottom: 10px;
}
    }
}
.new-btn{
    background-color: transparent;
    border-radius: 6px;
    border: 2px solid rgb(25, 202, 25);
    padding: 15px 30px;
}
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px !important;
    height: 64px;
    margin: 8px !important;
    border: 8px solid #9b59b6;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #9b59b6 transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loaderContianer{
    display: flex;
    height: 400px;
    justify-content: center;
    align-items: center;
  }
  .form-container{
    background-color: #fff;
    border-radius: 22px;
    box-shadow: 0 0 16px rgba(17,17,26,.1);
    width: 50%;
    margin: auto;
    padding: 20px;
    margin-top: 20px;
    div{
      width: 100%;
      margin: 5px 0;
    }
    div input{
      width: 100%;
    }
    label.ok{
      margin-right: 10px;
      color: #757474;
      font-weight: 700;
    }
  
   
      select{
        padding: 10px 20px;
        border-radius: 6px;
      }
   
    textarea{
      display: block;
      width: 100%;
      border: 1px solid #919191;
      border-radius: 8px;
      margin-top: 10px;
    }
    button{
      border: none;
      color: white;
      background-color: #9b59b6;
      padding: 5px 20px;
      border-radius: 6px;
      margin-top: 10px;
      font-size: 18px;
    }
  }
  .select{
    margin-left: 20px;
  }
  .back-btn{
    padding: 5px 10px;
    font-size: 20px;
    color: white;
    font-weight: 600;
    background-color: red;
    border-radius: 8px;
    margin-left: 30px;
    cursor: pointer;
  }
  .done{
    background-color: #3d9e3d;
    padding: 10px 20px;
    color: white;
    width: 30% !important;
    margin: auto !important;
    text-align: center;
  }