.login-container{
    width: 100%;
    height: 100vh;
    background: rgb(36,50,92);
    background: linear-gradient(0deg, rgba(36,50,92,0.9836309523809523) 0%, rgba(5,49,175,1) 24%, rgba(5,41,142,1) 53%);
    position: relative;
    .box{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        .input{
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
        background-color: white;
        margin-bottom: 10px;
    border: 1px solid #dcdbdb;
    border-radius: 6px;
    box-shadow: 0px 5px 8px 0px #dcdbdb;
    color: #ada7a7;
        }
        .form-btn{
            background-color: #19306F;
            border: none;
            border-radius: 8px;
            width: 100%;
            color: white;
            padding: 15px;
        }
    }
}